// External imports
import * as Sentry from '@sentry/angular';
// Modules
import { ErrorHandler, NgModule, inject, provideAppInitializer } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentsModule} from './components/components.module';
// Page Components
import {AppComponent} from './pages/app/app.component';
import {ARLogger} from '@relayter/core';
import {Toaster} from './classes/toaster.class';
import {RelayterModule} from './pages/relayter/relayter.module';
import {BaseRouterModule} from './router/router.module';
import {CountryUtil} from './classes/country.util';
import {environment} from '../environments/environment';
import {httpInterceptorProviders} from './http-interceptors';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {NUCEmptyStateModule, ToastService} from '@relayter/rubber-duck';
import {RELAYTER_DATE_FORMATS} from './pipes/rl-date.pipe';
import {CustomDateAdapter} from './adapters/custom-date.adapter';
import langEnGb from '@angular/common/locales/en-GB';
import langNl from '@angular/common/locales/nl';
import langFr from '@angular/common/locales/fr';
import langDe from '@angular/common/locales/de';
import {Router, TitleStrategy} from '@angular/router';
import {SEGMENT_SERVICE} from './services/segment/segment.service.interface';
import {RLSegmentService} from './services/segment/rl-segment.service';
import {RLMockSegmentService} from './services/segment/rl-mock-segment.service';
import {RelayterPageTitleStrategy} from './router/relayter-page-title-strategy';
import {registerLocaleData} from '@angular/common';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {OnboardingContainerComponent} from './pages/onboarding/onboarding-container.component';
import {UnauthorizedComponent} from './pages/unauthorized/unauthorized.component';

// Register most common languages (and variants) for (date) localization
registerLocaleData(langEnGb);
registerLocaleData(langNl);
registerLocaleData(langFr);
registerLocaleData(langDe);

const imports = [];
let segmentService;
if (environment.ENV === environment.ENVIRONMENTS.PRODUCTION || environment.ENV === environment.ENVIRONMENTS.DEVELOPMENT) {
    segmentService = RLSegmentService;
}else {
    segmentService = RLMockSegmentService;
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        ComponentsModule,
        RelayterModule,
        BaseRouterModule,
        ...imports,
        NUCEmptyStateModule
    ],
    declarations: [
        AppComponent,
        NotFoundComponent,
        UnauthorizedComponent,
        OnboardingContainerComponent
    ],
    bootstrap: [AppComponent],
    providers: [
        httpInterceptorProviders,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => {
            inject(Sentry.TraceService);
            return;
        }),
        {provide: MAT_DATE_LOCALE, useValue: navigator.language},
        {provide: MAT_DATE_FORMATS, useValue: RELAYTER_DATE_FORMATS},
        {provide: DateAdapter, useClass: CustomDateAdapter },
        {provide: SEGMENT_SERVICE, useClass: segmentService},
        {provide: TitleStrategy,  useClass: RelayterPageTitleStrategy, deps:[SEGMENT_SERVICE]},
    ]
})

export class AppModule {

    constructor(toastService: ToastService) {
        // Do some setup
        ARLogger.setEnvironment(environment.ENV);
        /* "@ts-expect-error" */
        Toaster.initialize(toastService);
        CountryUtil.initialize();
    }
}
